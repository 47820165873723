export function GetFetchData( localStorageName: string, fileName: string, handleData: Function ) {
    const lsExpKey = localStorageName + 'Expiration';
    
    let lsData: string | null = localStorage.getItem( localStorageName );
    let lsExpiration: string | null = localStorage.getItem( lsExpKey );

    let isExpired: boolean = true;
    if ( lsExpiration !== null ) {
        isExpired = new Date().getTime().toString() > lsExpiration;
    }

    if ( lsData !== null && !isExpired ){
        handleData( lsData );
        //console.log( lsExpKey + ': not expired' );
    } else {
        //console.log( lsExpKey + ': expired ');
        let dataPromise = fetch( fileName );
        dataPromise.then( response => {
            response.text().then( ( jsonText ) => {
                let currentTime: number = new Date().getTime();
                let newExpiration: string = ( currentTime + ( 60 * 60 * 1000 ) ).toString(); //one hour ( 60min, 60 sec, 1000ms)
                
                localStorage.setItem( lsExpKey, newExpiration );
                localStorage.setItem( localStorageName, jsonText );
                handleData( jsonText );
            } );
        })
    }
}