import React from 'react';
import { ITournament, ITournamentAgeGroupResults, ITournamentDate } from '../../interfaces/ITournament';

import './tournament.styles.scss';

export default class Tournament extends React.Component<ITournament, {}>{

    render(){

        let showResults = ( results: string[] | undefined ) => {
            if ( results === undefined ){
                return <td>TBD</td>
            } else {
                return <td>
                    <ol className='tournament-results'>
                        { results.map( ( r, index )=> { return (
                            <li key={ index }>
                                {r}
                            </li>
                        ) } ) }
                    </ol>
                </td>
            }
        }

        let showBrackets = (): React.JSX.Element => {
            return(
                <table className='tournament-grid'>
                    <thead>
                        <tr>
                            <th className='tournament-age'>Age</th>
                            <th className='tournament-bracket'>Bracket*</th>
                            <th className='tournament-rules'>Rules</th>
                            <th className='tournament-winner'>Results</th>
                        </tr>
                    </thead>
                    <tfoot>
                        <tr><td colSpan={5}>*Brackets will updated frequently via Google Drive during the tournament </td></tr>
                    </tfoot>
                    <tbody>
                        { this.props.Bracket.map( ( b, index )=> { return (
                            <tr key={ index }>
                                <td>{b.Age}</td>
                                <td><a href={b.BracketURL} target='_blank' rel='noopener noreferrer'><img src="i/bracket-icon.png" alt="Bracket" /></a></td>
                                <td><a href={b.RulesURL} target='_blank' rel='noopener noreferrer'><img src="i/rules-icon.png" alt="Rules" /></a></td>
                                { showResults( b.Results ) }
                            </tr>
                        ) } ) }
                    </tbody>
                </table>
            )
        }

        let showDates = (): React.JSX.Element => {
            let getDate = ( date: ITournamentDate ) => {
                return ( <React.Fragment>{date.Month} {date.Date.toString()}<sup>{date.Ordinal}</sup></React.Fragment>)
            }

            return(
                <React.Fragment>{getDate(this.props.StartDate)} - {getDate(this.props.EndDate)}</React.Fragment>
            )
        }

        let showLocations = (): React.JSX.Element => {
            return(
                <div className='tournament-locations'>
                    Fields:
                    <ul>
                        { this.props.Location.map( ( l, index )=> { return (
                            <li key={ index }>
                                <a href={l.MapsURL} target='_blank' rel='noopener noreferrer'>{l.Name}</a> in {l.City}
                                { l.Notes !== undefined && <span> ({l.Notes})</span>}
                            </li>
                        ) } ) }
                    </ul>
                </div>
            )
        }

        let showNotes = (): React.JSX.Element | null => {
            if ( this.props.Note !== undefined ){
                return(
                    <div className='tournament-notes'>
                    Notes:
                    <ul>
                    { this.props.Note.map( ( note, index )=> { return (
                        <li key={ index }>
                            { note }
                        </li>
                    ) } ) }
                    </ul>
                    </div>
                )
            } else {
                return null;
            }
        }

        return( 
            <article className='tournament'>
                <header>{this.props.Title}, {showDates()}</header>
                { this.props.Description }
                { showBrackets() }

                { showLocations() }
                { showNotes() }
            </article> 
        )
    }
}