import React from 'react';
import { SportsDisplayedContext } from '../../context/sportsDisplayed';
import { SportsDisplayedType } from '../../@types/sportsDisplayedType';
import { Convert, ITournamentRecap } from '../../interfaces/ITournamentRecap';
import TournamentRecap from '../../components/tournamentRecap/tournamentRecap';
import { GetFetchData } from '../../utility/fetch';

import '../../global.styles.scss';
import '../views.styles.scss';
import './blog.styles.scss';
import Tournament from '../../components/tournament/tournament';
import { ITournament } from '../../interfaces/ITournament';

interface IProps{}
interface IState{
    tournamentRecap: ITournamentRecap[];
}

export default class Blog extends React.Component<IProps, IState>{
    static contextType = SportsDisplayedContext;

    constructor (props: any){
        super(props);
       
        this.setTournamentData = this.setTournamentData.bind(this);
    }

    setTournamentData( data: string ){
        let tournamentData = Convert.toITournamentRecap( data );
        this.setState({ tournamentRecap: tournamentData });
    }

    componentDidMount(){
        GetFetchData( 'tournamentRecap', 'data/tournamentRecap.json', this.setTournamentData );
    }

    render(){
        const { isBaseballSelected, isSoftballSelected } = (this.context as SportsDisplayedType);

        let findTournamentYear = ( year: number ): ITournamentRecap | undefined => {
            let lsTournamentRecap = localStorage.getItem('tournamentRecap');
    
            if ( lsTournamentRecap !== undefined && lsTournamentRecap !== null ){
                let recapYears = Convert.toITournamentRecap( lsTournamentRecap );
                let tournamentYear: ITournamentRecap | undefined = recapYears.find( ry => ry.Year === year );
    
                return tournamentYear;
            } else {
                return undefined;
            }
        }

        let tournamentRecap2024: ITournamentRecap | undefined = findTournamentYear( 2024 );
        let tournamentRecap2023: ITournamentRecap | undefined = findTournamentYear( 2023 );
        let tournamentRecap2022: ITournamentRecap | undefined = findTournamentYear( 2022 );
        let tournamentRecap2021: ITournamentRecap | undefined = findTournamentYear( 2021 );
        let tournamentRecap2020: ITournamentRecap | undefined = findTournamentYear( 2020 );
        let tournamentRecap2019: ITournamentRecap | undefined = findTournamentYear( 2019 );

        let May2024Tournment: ITournament = {
            Title: 'Spring Tournament',
            Description: '',
            StartDate: { Date: 1, Month: 'May', Ordinal: 'st' },
            EndDate: { Date: 5, Month: 'May', Ordinal: 'th' },
            Bracket: [
                {
                    Age: '10u',
                    BracketURL: 'https://docs.google.com/spreadsheets/d/1pkuvWOZlx52rUrBTjOdlfiHLxAdfjWJRhcGYXaKn7l4/edit?usp=drive_link',
                    RulesURL: 'https://docs.google.com/document/d/1YYxaeKedtaNZyYSU0Y84afoOEE-l4HobBdKAZPsn4ig/edit?usp=sharing',
                    Results: [ 'Tri Valley', 'Morton' ]
                },
                {
                    Age: '12u',
                    BracketURL: 'https://docs.google.com/spreadsheets/d/1a50UjhoCsjubV2qR-fMZxQ5V5lf5PHM8Cd8DEKXGTiU/edit?usp=drive_link',
                    RulesURL: 'https://docs.google.com/document/d/1pMwaDHX3GC4--FOkmamk9-UTGiSJl2Kt11wNK3HX4ZI/edit?usp=sharing',
                    Results: [ 'Olympia', 'USA Prime Illinois'  ]
                },
                {
                    Age: '14u',
                    BracketURL: 'https://docs.google.com/spreadsheets/d/1cHR7qpkNlV8y7yys1oRUzBNhT5IiOAdjOKCqexRSC-g/edit?usp=drive_link',
                    RulesURL: 'https://docs.google.com/document/d/1COOIgXGtGGUk8F5ezwkHdqEN0XvfBN0Qo3RoWVP3Abc/edit?usp=sharing',
                    Results: [ 'Top Tier', 'Stringers' ]
                }
            ],
            Location: [
                {
                    Name: 'Central Junior High School',
                    MapsURL: 'https://www.google.com/maps/place/Central+Junior+High+School/@40.6642753,-89.5722625,19z/data=!4m5!3m4!1s0x880a5847d32fc4bf:0x7ce04b8d52c56a7c!8m2!3d40.6643549!4d-89.5717186',
                    City: 'East Peoria'
                },
                {
                    Name: 'Fondulac/Veterans Park',
                    City: 'East Peoria',
                    MapsURL: 'https://www.google.com/maps/place/Fondulac+Park,+East+Peoria,+IL+61611/@40.6576654,-89.5713119,17z/data=!3m1!4b1!4m2!3m1!1s0x880a58411a4da60f:0x7ef57a0ff5b244cf',
                    Notes: 'Turf batters box and mounds. METAL CLEATS ARE PROHIBITED!'
                }
            ],
            Note: [
                'No open batting cages. Hitting into fence is prohibited.',
                'We ask coaches to turn in 3 game balls prior to your first game.'
            ]

        }
//<img src='i/PrimeILBaseballLogo.png' alt='USA Prime Illinois Baseball' className='float-start' />
        return (
            <div className={`content-view blog ${ isBaseballSelected() ? 'showBaseball' : '' } ${ isSoftballSelected() ? 'showSoftball' : '' } `}>

                <article>
                    <header>Midwest Gamblers Is Now USA Prime Illinois!</header>
                    <img src='i/logo.png' alt='USA Prime logo' className='float-start' />
                    <p>Starting with the 2024 season, Midwest Gamblers will be joining the USA Prime national baseball program!</p>
                    <blockquote>"USA Prime brings a fantastic platform to the region to develop young players into leaders and ball players. I am excited to partner with USA Prime in Peoria to connect young players with the level of baseball that fits their skill set and to grow these players in all areas." ~ David Hidden.</blockquote>
                    <p>Established in 2017, USA Prime aims to prepare, develop, and mentor athletes to achieve their goals in baseball and ultimately reach their full potential.</p>
                </article>
                
                <TournamentRecap tournamentRecap={ tournamentRecap2024 }></TournamentRecap>
                <Tournament {...May2024Tournment }/>

                <TournamentRecap tournamentRecap={ tournamentRecap2023 }></TournamentRecap>
                <TournamentRecap tournamentRecap={ tournamentRecap2022 }></TournamentRecap>
                <TournamentRecap tournamentRecap={ tournamentRecap2021 }></TournamentRecap>
                <TournamentRecap tournamentRecap={ tournamentRecap2020 }></TournamentRecap>
                <TournamentRecap tournamentRecap={ tournamentRecap2019 }></TournamentRecap>
            </div>
        );
    } 
}