import React from 'react';
import ReactDOM from 'react-dom/client';
//import reportWebVitals from './reportWebVitals';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Header from './components/header/header';
import Navigation from './components/navigation/navigation';
import Footer from './components/footer/footer';
import Blog from './views/blog/blog';
import Teams from './views/teams/teams';
import About from './views/about/about';
import Contact from './views/contact/contact';
import NotFound from './views/notFound/notFound';
import Sponsor from './components/sponsor/sponsor';
import './index.styles.scss';
import { SportsDisplayedProvider } from './context/sportsDisplayed';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter>
    <React.StrictMode>
      <SportsDisplayedProvider>
        <Header />
        <Navigation />
        <main className="container">
          <div className="row">
            <div className="col-md-9">
              <Routes>
                <Route path='' element={ <Blog /> } />
                <Route path='/about' element={ <About /> } />
                <Route path='/teams' element={ <Teams /> }/>
                <Route path='/contact' element={ <Contact />}/>
                <Route path='*' element={ <NotFound />}/>
              </Routes>
            </div>
            <Sponsor />
          </div>
        </main>
        <Footer />
      </SportsDisplayedProvider>
    </React.StrictMode>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
