import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './header.styles.scss';
import { NavLink } from 'react-router-dom';

export default class Header extends React.Component{

    render(){
        return (
            <div>
                <div id="social-media-bar">
                    <div className="container">
                        <a href="https://www.facebook.com/MidwestGamblers" target="_blank" rel="noreferrer">Facebook</a>
                        <a href="https://twitter.com/PrimeIllinois" target="_blank" rel="noreferrer">Twitter</a>
                        <NavLink to="/contact">Contact Us</NavLink>
                    </div>
                </div>
                <header className="container site-header">
                    <div className="row">
                        <div className="col-md-12">
                            <NavLink to="/"><img src="i/logo.png" alt="USA Prime Logo" /></NavLink>
                            <h1>USA PRIME ILLINOIS</h1>
                            <h2>Youth Travel Baseball</h2>
                        </div>
                    </div>
                </header>
            </div>
        );
    }
}