import React from 'react';
import { Convert, ICoach, IPlayer, ITeam } from '../../interfaces/ITeams';
import { GetFetchData } from '../../utility/fetch';
import { SportsDisplayedContext } from '../../context/sportsDisplayed';
import { SportsDisplayedType } from '../../@types/sportsDisplayedType';

import '../../global.styles.scss';
import '../views.styles.scss';
import './teams.styles.scss';

interface IProps{}
interface IState{
    rosterTeams: ITeam[];
}

export default class Teams extends React.Component<IProps, IState>{
    static contextType = SportsDisplayedContext;

    constructor (props: any){
        super(props);
       
        this.setTeamData = this.setTeamData.bind(this);
    }

    setTeamData( data: string ){
        let sponsorData = Convert.toITeam( data );
        this.setState({ rosterTeams: sponsorData });
    }

    componentDidMount(){
        GetFetchData( 'roster', 'data/roster.json', this.setTeamData );
    }

    render(){
        const { isBaseballSelected, isSoftballSelected } = (this.context as SportsDisplayedType);

        let showSpinner = () => { return <div>spinner!</div>};
        let showTeams = () => this.state.rosterTeams.map( ( team ) => {

            let sport: string = ( team.IsBaseball ) ? 'baseball' : 'softball';
            let showRoster = ( players: IPlayer[] ) => {
                
                let showSchool = ( team: ITeam, player: IPlayer ) => {
                    if ( !team.ShowSchool ) { return; }

                    return (<span>, { player.School }</span>)
                }

                let showCollege = ( team: ITeam, player: IPlayer ) => {
                    if ( player.College === undefined ) { return; }
                    
                    return (
                        <div className="commit">Committed to { player.College } </div>
                    )
                }

                let showPlayer = ( team: ITeam, index: number, player: IPlayer ) => {
                    return (
                        <li key={index}>{ player.Name}{showSchool( team, player) }{showCollege( team, player )}</li>
                    );
                }
                
                if ( players.length === 0 ){ return null; }
                return (
                    <div className='players'>
                        <h4>Players</h4>
                        <ul>
                        { players.map( ( player, index ) => showPlayer( team, index, player ) )}
                        </ul>
                    </div>
                ) 
            }
            
            let showCoaches = ( coaches: ICoach[] ) => {
                if ( coaches.length === 0 ){ return; }
                return (
                    <div className='coaches'>
                        <h4>Coaches</h4>
                        <ul>
                            { coaches.map( (coach, index) => <li key={index}>{ coach.Name}, {coach.Title}</li>)}
                        </ul>
                    </div>
                )            
            };
                        
            return(
                <section key={team.ID} className={ sport }>
                    <h3>{ team.Description }</h3>
                    <div className='team-members d-flex flex-row'>
                        { showRoster( team.Players ) }
                        { showCoaches( team.Coaches ) }
                    </div>
                </section>
            );
        });

        let display;
        if ( this.state === null || this.state.rosterTeams === null ){ 
            display = showSpinner();
        } else {
            display = showTeams();
        };
    
    
        return (
            <div className={`content-view teams ${ isBaseballSelected() ? 'show-baseball' : '' } ${ isSoftballSelected() ? 'show-softball' : '' } `}>
                <h2>Teams</h2>
                { display }
            </div>
        );
    }
}