import React from 'react';
import { Convert, ISponsor } from '../../interfaces/ISponsor';
import { GetFetchData } from '../../utility/fetch';
import './sponsor.styles.scss';

interface IProps{}
interface IState{
    sponsors: ISponsor[];
}

export default class Sponsor extends React.Component<IProps, IState>{
    constructor (props: any){
        super(props);
       
        this.setSponsorData = this.setSponsorData.bind(this);
      }

    setSponsorData( data: string ){
        let sponsorData = Convert.toISponsor( data );
        this.setState({ sponsors: sponsorData });
    }

    componentDidMount(){
        GetFetchData( 'sponsors', 'data/sponsor.json', this.setSponsorData );
    }

    render(){
        let showSpinner = () => { return <div>spinner!</div>};
        
        let showSponsorLevel = ( level: number ) => {
            const levelSponsors = this.state.sponsors
                .filter( ( sponsor ) => { return sponsor.level === level && sponsor.isPartner === false } );
            if ( levelSponsors.length === 0 ){ return null; }

            const imageSponsors = levelSponsors.filter( ( sponsor ) => { return sponsor.image.length > 0 });
            const nonImageSponsors = levelSponsors.filter( ( sponsor ) => { return sponsor.image.length === 0 });

            let buildTitle = ( sponsor: ISponsor ) => {
                return sponsor.name + ', ' + sponsor.team +' Level ' + sponsor.level + ' Sponsor';
            }

            let showImageSponsors = () => {
                if ( imageSponsors.length === 0 ){ return null; }

                return (
                    <div>
                        { imageSponsors.map( ( sponsor, index ) => 
                            <a key={index} href={ sponsor.url} target='_blank' rel='noreferrer' title={ buildTitle( sponsor ) }>
                            <img src={ sponsor.image} alt={ buildTitle( sponsor ) }/>
                            </a>
                        ) }
                    </div>
                )
            }

            let showNonImageSponsors = () => {
                if ( nonImageSponsors.length===0 ){ return null; }
                
                return (
                    <ul>
                        { nonImageSponsors.map( ( sponsor, index ) => 
                            <li key={index}><a href={ sponsor.url} target='_blank' rel='noreferrer' title={ buildTitle( sponsor ) }>
                            {sponsor.name}
                            </a>
                            </li>
                        ) }
                    </ul>
                )
            }

            return (
                <div className={'level-' + level}>
                    { showImageSponsors() }
                    { showNonImageSponsors() }
                </div>
            )
        }

        let showSponsors = () => {
            return (
                <div>
                    { showSponsorLevel(4) }
                    { showSponsorLevel(3) }
                    { showSponsorLevel(2) }
                    { showSponsorLevel(1) }
                </div>
            );           
        }
        
        let display;
        if ( this.state === null || this.state.sponsors === null ){ 
            display = showSpinner();
        } else {
            display = showSponsors();
        };

        
        return (
            <div id="sponsors" className="col-md-3">
                <h2>Sponsors</h2>
                { display }
            </div>
        );
    }
}