import React from 'react';
import './footer.styles.scss';
//import instagramLogo from '../../../public/i/socialmedia/instagram_24.png';

export default class Footer extends React.Component{

    render(){
        return (
            <footer className='mt-auto'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-4 text-start'>
                            <span>USA PRIME ILLINOIS</span><br />
                            232 Jay Street<br />
                            East Peoria, IL 61611
                        </div>
                        <div className='col-md-4 text-center d-flex align-items-end justify-content-center'>
                            Copyright 2024
                        </div>
                        <div className='col-md-4 text-end'>
                            <a title='USA Prime Sports National Website' href='https://www.usaprimesports.com/USA' target='_blank' rel='noreferrer'>USA Prime Sports National</a>
                            <br />
                            <a title='USA Prime Sports National Instagram' href='https://www.instagram.com/usaprimebaseball/' target='_blank' rel='noreferrer'>
                                <img alt='' src={process.env.PUBLIC_URL + '/i/socialmedia/instagram_24.png'} />
                            </a>
                            <a title='USA Prime Sports National Twitter' href='https://twitter.com/USA_PrimeBB' target='_blank' rel='noreferrer'>
                                <img alt='' src={process.env.PUBLIC_URL + '/i/socialmedia/twitter_24.png'} />
                            </a>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}