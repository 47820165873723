import React from 'react';
import './navigation.styles.scss';
import { NavLink } from 'react-router-dom';
import { Sports, SportsDisplayedContext, } from '../../context/sportsDisplayed';
import { SportsDisplayedType } from '../../@types/sportsDisplayedType';

export default class Navigation extends React.Component{
    static contextType = SportsDisplayedContext;

    render(){
        const { toggleSport, isBaseballSelected, isSoftballSelected } = (this.context as SportsDisplayedType);

        const onButtonClick = ( event: React.MouseEvent<HTMLButtonElement>, sport: string ) => {
            event.preventDefault();
        
            toggleSport(sport);
        };

        let sportButton = ( sport: string ) => {
            let buttonText: string = '',
                buttonClasses: string = 'btn btn-outline-primary ' + sport + ' ',
                isSportSelected: boolean, 
                isOnlyActiveSport: boolean = false,
                sportText: string = '',
                titleText: string = '',
                airaLabelText: string = '';

            if ( sport === Sports.Baseball ){
                //console.log('is Baseball');
                isSportSelected = isBaseballSelected();
                sportText = 'Baseball';
                isOnlyActiveSport = ( isSportSelected && !isSoftballSelected() );
            } else {
                //console.log( 'is Softball');
                isSportSelected = isSoftballSelected();
                sportText = 'Softball';
                isOnlyActiveSport = ( isSportSelected && !isBaseballSelected() );
            }

            titleText = 'Click to ' + ( isSportSelected ? 'hide ' : 'show' ) + ' ' + sportText.toLocaleLowerCase() + ' information.';
            if ( isOnlyActiveSport ) {
                titleText = 'At least one sport must be shown.';
            }
            buttonText = sportText;
            buttonClasses += isSportSelected ? 'active ' : '';
            buttonClasses += isOnlyActiveSport ? 'prevent-click ' : '';
            airaLabelText = 'Button to show and nide ' + sportText.toLowerCase() + ' information.'

              return (
                <button type="button" 
                    aria-label={ airaLabelText }
                    title= { titleText }
                    className={ buttonClasses } 
                    aria-pressed={ isSportSelected ? 'true' : 'false' }
                    data-bs-toggle="button"
                    onClick={ isOnlyActiveSport ? undefined : (e) => onButtonClick(e, sport.toString() ) }>
                    <span className='sport-icon'></span>
                    <span className='button-text'>{ buttonText }</span>
                </button>
            );
        };

        return (
            <nav className='site-nav'>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-start">
                            <NavLink to="/">Home</NavLink>
                            <NavLink to="/teams">Teams</NavLink>
                            <NavLink to="/about">About</NavLink>
                        </div>
                    </div>
                </div>
            </nav>
        );
    }
}            

/*
                            <div className='sports-filter'>
                                <span className='label text-muted'>Filter<span> By Sport</span>:</span>
                                <div className="btn-group" role="group" aria-label="Baseball / Softball Filter">
                                    { sportButton(Sports.Baseball) }
                                    { sportButton(Sports.Softball) }
                                </div>
                            </div>
*/