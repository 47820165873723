import React from 'react';
import { ITournamentRecap } from '../../interfaces/ITournamentRecap';

import './tournamentRecap.styles.scss';
import { SportsDisplayedContext } from '../../context/sportsDisplayed';

interface IProps{
    tournamentRecap: ITournamentRecap | undefined
}
interface IState{}

export default class TournamentRecap extends React.Component<IProps, IState>{
    static contextType = SportsDisplayedContext;

    render(){
        if ( this.props.tournamentRecap === null || this.props.tournamentRecap === undefined ){
            return; 
        }

        
        let recap = this.props.tournamentRecap;
        let yearSports = Array.from( new Set( recap.Team.map( t => t.Sport ) ) ).join( ' ' );
        
        let isOldName = () => {
            if ( recap.Year >= 2024 ) { return; }
            
            return ( <span className='old-name'> (Midwest Gamblers)</span> )
        }       

        let showTeams = () => recap.Team.map( ( team, index ) => {
            let showImage = () => {
                if ( team.ImageUrl === undefined ){ return; }

                return (
                    <img 
                        className = 'team-photo'
                        alt={ team.ImageAltText } 
                        title={ team.ImageAltText }
                        src={ 'i/teams/' + team.ImageUrl }/>
                )
            }

            return(
                <section key={ index }>
                    <h3>{team.Name}</h3>
                    { showImage() }
                    <ul>
                        { team.Tournament.map( ( t, index )=> { return (
                            <li key={ index } dangerouslySetInnerHTML={{ __html: t.Place + ', ' + t.Name }}></li>
                        ) } ) }
                    </ul>
                </section>
            )
        });
        
        return (
            <article className={ 'tournament-recap ' + yearSports }>
                <header>
                    { recap.Year.toString() } Tournament Recap
                    { isOldName() }
                </header>
                { showTeams() }
            </article>
        );
    }
}