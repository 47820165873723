import React, { createContext, useContext } from 'react';
import { SportsDisplayedType } from '../@types/sportsDisplayedType';

export const enum Sports { 
    Baseball = 'baseball', 
    Softball = 'softball'
};

export const SportsDisplayedContext = createContext<SportsDisplayedType | null>( null );

interface SportsDisplayedProviderProps {
    children: React.ReactNode;
}

export const SportsDisplayedProvider: React.FC<SportsDisplayedProviderProps> = ({ children }) => {
    const [ sportsDisplayed, setSportsDisplayed ] = React.useState<string[]>( [Sports.Baseball.toString(), Sports.Softball.toString()] );

    const toggleSport = ( sport: string ) => {
        console.log( sport );
        let index: number = sportsDisplayed.indexOf( sport, 0 );
        if ( index === -1 ) {
            sportsDisplayed.push( sport );
        } else {
            if ( sportsDisplayed.length > 1 ){ //at least one sport must be selected
                sportsDisplayed.forEach( (item, index) => {
                    if(item === sport) sportsDisplayed.splice(index,1);
                });
            }
        };
        setSportsDisplayed([...sportsDisplayed]);
    }

    const isBaseballSelected = () => { 
        return sportsDisplayed.includes( Sports.Baseball.toString() ) 
    }
    const isSoftballSelected = () => { return sportsDisplayed.includes( Sports.Softball.toString() ) }

    return ( 
        <SportsDisplayedContext.Provider value={{ sportsDisplayed, toggleSport, isBaseballSelected, isSoftballSelected }}>
            {children}
        </SportsDisplayedContext.Provider>
    );
};

export const useSportsDisplayedContext = () => useContext( SportsDisplayedContext );