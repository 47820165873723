import React from 'react';
import '../../global.styles.scss';
import '../views.styles.scss';
import './contact.styles.scss';

export default class Contact extends React.Component{

    render(){
        return (
            <div className="content-view contact">
                <h2>Contact Us</h2>
                <p>If you have any questions, please contact our team president Dave Hidden at 309.340.5251.</p>

                <h2>Social Media</h2>
                <ul>
                    <li><a href='https://twitter.com/PrimeIllinois' target='_blank' rel='noreferrer'>Twitter</a></li>
                    <li><a href='https://www.facebook.com/' target='_blank' rel='noreferrer'>Facebook</a></li>
                </ul>
            </div>
        );
    }
}