import React from 'react';
import { NavLink } from 'react-router-dom';
import '../../global.styles.scss';
import '../views.styles.scss';
import './notFound.styles.scss';


export default class NotFound extends React.Component{

    render(){
        return (
            <div className="content-view not-found">
                <h2>Swing...and a miss!</h2>
                <p>It's ok, you get two more strikes.</p>
                <p>Head to the <NavLink to='/'>home page</NavLink> and we'll get that ball back in play in no time!</p>
            </div>
        );
    }
}