import React from 'react';
import '../../global.styles.scss';
import '../views.styles.scss';
import './about.styles.scss';

export default class About extends React.Component{

    render(){
        return (
            <div className='content-view about'>
                <article>
                    <h2>Mission Statement</h2>
                    <img src='i/PrimeILBaseballLogo.png' alt='USA Prime logo' className='float-start' />
                    <p>The mission of USA Prime Illinois is to inspire each player to do his best and develop a deeper appreciation for the great game of baseball.</p>
                    <p>Our players are taught to “Respect the Game”.  This means hustling on and off the field at all times, showing good sportsmanship to umpires and opponents, putting team goals first, listening to the coaches, supporting teammates, being thankful for the opportunity to play, and most importantly HAVING FUN!!!</p>
                    <p>By instilling this attitude at a young age we hope our players use this experience as a stepping stone as they develop, both on and off the field.</p>
                </article>
                <article>
                    <h2>Fundraising</h2>
                    <p>USA Prime Illinois is a 501(c)(3) non-profit travel baseball organization with players ranging from under 10 to 18.  Our goal is to develop these young men into responsible adults through the adversity, triumph and community of high-level competition.</p>
                    <p>To achieve this goal, our program is supported by fundraising and your gracious investment in the baseball futures of our youth.  Every dollar we receive goes towards field maintenance, tournament entry fees, equipment, uniforms and other miscellaneous items that directly support our players.  For 2025 we anticipate each team's expenses to exceed $10,000.</p>  
                    <p>We have four levels of sponsorship, with each offering a great opportunity for your business to gain exposure in the local community:</p>
                    <ul className='fundraising'>
                        <li><strong className='level blue'>Level 4, $1,000:</strong><div className='includes'>Includes your logo on the team banner*, USA Prime Illinois website and Facebook. You will also receive a sponsor plaque and jersey.</div></li>
                        <li><strong className='level blue'>Level 3, $500:</strong><div className='includes'>Includes your logo on the team banner*, USA Prime Illinois website and Facebook. You will also receive a sponsor plaque.</div></li>
                        <li><strong className='level blue'>Level 2, $250:</strong><div className='includes'>Includes your logo on the team banner*, USA Prime Illinois website and Facebook.</div></li>
                        <li><strong className='level blue'>Level 1, $100:</strong><div className='includes'>Includes your logo on USA Prime Illinois website and Facebook.</div></li>
                    </ul>
                    <p className='text-muted'>* To have banners ready for opening day, placement on team banners cannot be guaranteed after 3/1</p>
                    <ul>
                        <li><a title='Sponsorship Letter for 2025' href='https://drive.google.com/file/d/1JFyUaOb31089A-BHG0MdvLzyQrlwEANS/view?usp=sharing' target='_blank' rel='noreferrer'>2025 Sponsorship Letter</a></li>
                                                                         
                    </ul>
                </article>
                <article>
                    <h2>Player &amp; Parent Contracts</h2>
                    <p>As discussed at the first parent meeting of each year, every player and at least one parent/guardian are required to sign a contract outlining their respective expectations as part of a USA Prime Illinois team.</p>
                    <ul>
                        <li><a title='Player Contract for 2025' href='https://drive.google.com/file/d/14iFMceFJwBjU3eoJAeVLZzQSc3prjBzU/view?usp=sharing' target='_blank' rel='noreferrer'>2025 Player Contract</a></li>
                        <li><a title='Parent Contract for 2025' href='https://drive.google.com/file/d/1XzPZ_rjjZu75ZRpjXktyMg2v4lfWiFjV/view?usp=sharing' target='_blank' rel='noreferrer'>2025 Parent Contract</a></li>
                    </ul>
                    <p>Contracts will be collected at each team's first practice.</p>
                </article>
                <article>
                    <h2>Executive Board Members</h2>
                    <ul className='exec-board'>
                        <li><strong className='title blue'>President:</strong> David Hidden</li>
                        <li><strong className='title blue'>Vice President:</strong> John Krutke</li>
                        <li><strong className='title blue'>Asst Vice President</strong> Billy Flora</li>
                        <li><strong className='title blue'>Secretary:</strong> Megan Hidden</li>
                        <li><strong className='title blue'>Treasurer:</strong> Jessica Krutke</li>
                        <li><strong className='title blue'>Asst Treasurer:</strong> Jackie Flora</li>
                        <li><strong className='title blue'>Concessions:</strong> Jenny Bond, Bryon Ray</li>
                        <li><strong className='title blue'>Field Maintenance:</strong> Bryon Ray </li>
                        <li><strong className='title blue'>Member Services:</strong> Bryon Ray</li>
                        <li><strong className='title blue'>Public Relations:</strong> Rusty Powell</li>
                    </ul>
                </article>
            </div>
        );
    }
}